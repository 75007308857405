<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar color="primary" dark>
          <span>New Employee</span>
          <v-spacer></v-spacer>
          <v-icon>mdi-account</v-icon>
          <v-icon>mdi-plus</v-icon>
        </v-toolbar>
        <v-alert class="mt-2" type="error" dismissible v-for="(item, index) in empPostErrors" :key="index">
          {{ item.first() }}
        </v-alert>
      </v-col>
      <v-col offset-lg="8" lg="4" cols="12">
        <v-text-field label="Employee ID" v-model="employeeId"></v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field label="First Name" v-model="firstname"></v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field v-model="middlename">
          <template v-slot:label>
            <span>Middle Name</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field label="Last Name" v-model="lastname"></v-text-field>
      </v-col>
      <v-col lg="6" cols="12">
        <v-text-field v-model="email" type="email" :rules="[verifyEmail]">
          <template v-slot:label>
            <span>Email</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="6" cols="12">
        <v-text-field v-model="address">
          <template v-slot:label>
            <span>Address</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field v-model="businessNo">
          <template v-slot:label>
            <span>Business No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field v-model="mobileNo">
          <template v-slot:label>
            <span>Mobile No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field v-model="homeNo">
          <template v-slot:label>
            <span>Home No</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="notes" rows="1" no-resize auto-grow>
          <template v-slot:label>
            <span>Notes</span><small class="grey--text ml-2">(optional)</small>
          </template>
        </v-textarea>
      </v-col>
      <v-col cols="1">
        <v-btn color="primary" dark :loading="empPosting" @click="save">Save</v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn color="red" dark @click="$router.push('/employees')">Cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import empRepository from '@/repositories/employee.repository'

const saveDelay = searchDelay()

const defaultInputs = () => ({
  employeeId: null,
  firstname: null,
  middlename: null,
  lastname: null,
  businessNo: null,
  mobileNo: null,
  homeNo: null,
  address: null,
  email: null,
  notes: null,
})

export default {
  name: 'CreateEmployee',
  data() {
    return {
      ...defaultInputs(),
      ...postVars('emp'),
    }
  },
  methods: {
    verifyEmail(email = '') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (re.test(email ? email.toLowerCase() : '') || !email) || 'Invalid Email Address'
    },
    save() {
      if (!this.empPosting) {
        this.empPosting = true
        saveDelay(() => {
          const data = {
            id: this.employeeId,
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            business_no: this.businessNo,
            mobile_no: this.mobileNo,
            home_no: this.homeNo,
            address: this.address,
            email: this.email,
            notes: this.notes,
          }
          this.empPostErrors = []
          empRepository.store(data)
            .then(() => { this.$router.push('/employees') })
            .catch(e => { this.empPostErrors = errorHandler(e) })
            .then(() => { this.empPosting = false })
        })
      }
    },
    reset(vars) {
      Object.keys(defaultInputs()).forEach(e => {
        this[e] = vars[e]
      })
    },
  },
}
</script>
