import repository from '@/repositories/repository'

const resource = 'employees'

export default {
  store (data) {
    return repository.post(`${resource}`, data)
  },
  all (params) {
    return repository.get(`${resource}`, { params })
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  get (id) {
    return repository.get(`${resource}/${id}/edit`)
  },
  storeRate (id, data) {
    return repository.post(`${resource}/${id}/rate`, data)
  },
  storeAllowance (id, data) {
    return repository.post(`${resource}/${id}/allowances`, data)
  },
}
